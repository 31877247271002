import cable1 from "../../assets/images/business/part/01/seat_cable.jpg";
import cable2 from "../../assets/images/business/part/01/remote_cable.jpg";
import cable3 from "../../assets/images/business/part/01/ratch_cable.jpg";
import cable4 from "../../assets/images/business/part/01/bowden_cable.jpg";
import seat1 from "../../assets/images/business/part/02/mb-seat.png";
import seat2 from "../../assets/images/business/part/02/mb-seat-2.png";
import seat3 from "../../assets/images/business/part/02/mb-seat-3.png";
import seat4 from "../../assets/images/business/part/02/mb-seat-4.png";
import seat5 from "../../assets/images/business/part/02/mb-seat-5.png";
import bendingwire1 from "../../assets/images/business/part/03/seat.png";
import bendingwire2 from "../../assets/images/business/part/03/seat-2.png";
import bendingwire3 from "../../assets/images/business/part/03/seat-3.png";
import seat from "../../assets/video/seat.MP4";
import mbseat1 from "../../assets/video/mbseat1.mp4";
import mbseat2 from "../../assets/video/mbseat2.mp4";
export const productData = [
  {
    video: "",
    image: cable1,
    name: "seat_cable",
  },
  {
    image: cable2,
    name: "remote_cable",
  },
  {
    image: cable3,
    name: "ratch_cable",
  },
  {
    image: cable4,
    name: "bowden_cable",
  },
];
export const productVideo = [
  {
    video: seat,
  },
  { video: mbseat1 },
  { video: mbseat2 },
];
// export const productData1 = [
//   {
//     image: seat1,
//   },
//   {
//     image: seat2,
//   },
//   {
//     image: seat3,
//   },
//   {
//     image: seat4,
//   },
//   {
//     image: seat5,
//   },
// ];

export const productData2 = [
  {
    image: bendingwire1,
    name: "seat_back",
  },
  {
    image: bendingwire2,
    name: "seat_cushion",
  },
  {
    image: bendingwire3,
    name: "etcs",
  },
];
