import { useState } from "react";
import factory from "../../assets/images/intro/factory5.png";
import factory2 from "../../assets/images/intro/factory4.png";
export const FactoryStatus = () => {
  const [line, setLine] = useState<number>(0);
  const lineHandler = (num: number) => {
    setLine(() => {
      return num;
    });
  };
  return (
    <div className="max-w-screen-lg w-full pt-32 md:pt-64 pb-40 md:pb-80 m-auto">
      <h3 className="text-5xl sm:text-6xl font-bold text-center">
        <svg
          viewBox="0 0 75 74"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="headerGuard inline-block mr-10 sm:mr-16"
        >
          <path d="M1.5 73L49.5 1H73.5L23.5408 73H1.5Z" stroke="#40AEB8" />
        </svg>

        <span className="leading-normal esamanru font-bold text-main">
          공장현황
        </span>
        <svg
          viewBox="0 0 73 72"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="headerGuard inline-block ml-10 sm:ml-16"
        >
          <path d="M0.5 72L48.5 0H72.5L22.5408 72H0.5Z" fill="#0B2152" />
        </svg>
      </h3>
      <ul className="recruit_border pt-32 sm:pt-64">
        <li
          className={`box-border text-3xl sm:text-4xl align-middle inline-block w-1/2 h-28 ${
            line == 0 && "selectList"
          }`}
        >
          <button
            className="w-full h-full flex flex-wrap content-center justify-center"
            onClick={() => lineHandler(0)}
          >
            명보 본사
          </button>
        </li>
        <li
          className={`box-border text-3xl sm:text-4xl align-middle inline-block w-1/2 h-28 ${
            line == 1 && "selectList"
          }`}
        >
          <button
            className="w-full h-full flex flex-wrap content-center justify-center"
            onClick={() => lineHandler(1)}
          >
            명보 1공장
          </button>
        </li>
      </ul>
      {line === 0 && (
        <div id="continue" className="pt-32 sm:pt-64">
          <div className="stt text-center">
            <p className="font-bold text-4xl sm:text-5xl text-center esamanru text-main uppercase">
              명보 본사
            </p>
            <div className="headBar bg-main2 m-auto mt-11"></div>
          </div>
          <div className="max-w-screen-lg w-full m-auto ">
            <img
              src={factory}
              alt="공장 이미지"
              className="w-full py-32 sm:py-64"
            />
            <table className=" info border-t-2 border-b-2 w-full border-gray-700">
              <tr className="border-b-2">
                <th className="font-bold table-th w-2/5 leading-loose sm:leading-loose tracking-tight">
                  소재지
                </th>
                <td className="font table-td w-3/5 tracking-tight">
                  경주시 외동읍 내외로 284-5
                </td>
              </tr>
              <tr className="border-b-2">
                <th className="font-bold table-th w-2/5 leading-loose sm:leading-loose tracking-tight">
                  토지
                </th>
                <td className="font table-td w-3/5 tracking-tight">2,700평</td>
              </tr>
              <tr className="border-b-2">
                <th className="font-bold table-th w-2/5 leading-loose sm:leading-loose tracking-tight">
                  건물
                </th>
                <td className="font table-td w-3/5 tracking-tight">700평</td>
              </tr>
              <tr className="border-b-2">
                <th className="font-bold table-th w-2/5 leading-loose sm:leading-loose tracking-tight">
                  가(130평)
                </th>
                <td className="font table-td w-3/5 tracking-tight uppercase">
                  럼버서포트(허리지지대)
                </td>
              </tr>
              <tr className="border-b-2">
                <th className="font-bold table-th w-2/5 leading-loose sm:leading-loose tracking-tight">
                  나(120평)
                </th>
                <td className="font table-td w-3/5 tracking-tight">
                  럼버서포트(허리지지대)
                </td>
              </tr>
              <tr className="border-b-2">
                <th className="font-bold table-th w-2/5 leading-loose sm:leading-loose tracking-tight">
                  다(120평)
                </th>
                <td className="font table-td w-3/5 tracking-tight">
                  럼버서포트(허리지지대)
                </td>
              </tr>
              <tr className="border-b-2">
                <th className="font-bold table-th w-2/5 leading-loose sm:leading-loose tracking-tight">
                  A(150평)
                </th>
                <td className="font table-td w-3/5 tracking-tight">CABLE</td>
              </tr>
              <tr className="border-b-2">
                <th className="font-bold table-th w-2/5 leading-loose sm:leading-loose tracking-tight">
                  B(150평)
                </th>
                <td className="font table-td w-3/5 tracking-tight">CABLE</td>
              </tr>
              <tr className="">
                <th className="font-bold table-th w-2/5 leading-loose sm:leading-loose tracking-tight">
                  C(75평)
                </th>
                <td className="font table-td w-3/5 tracking-tight">CABLE</td>
              </tr>
            </table>
          </div>
        </div>
      )}
      {line === 1 && (
        <div id="continue" className="pt-32 sm:pt-64">
          <div className="stt text-center">
            <p className="font-bold text-4xl sm:text-5xl text-center esamanru text-main uppercase">
              명보 1공장
            </p>
            <div className="headBar bg-main2 m-auto mt-11"></div>
          </div>
          <div className="max-w-screen-lg w-full m-auto ">
            <img
              src={factory2}
              alt="공장 이미지"
              className="w-full py-32 sm:py-64"
            />

            <table className=" info border-t-2 border-b-2 w-full border-gray-700">
              <tr className="border-b-2">
                <th className="font-bold table-th w-2/5 leading-loose sm:leading-loose tracking-tight">
                  소재지
                </th>
                <td className="font table-td w-3/5 tracking-tight">
                  경주시 외동읍 석계리 2074-8
                </td>
              </tr>
              <tr className="border-b-2">
                <th className="font-bold table-th w-2/5 leading-loose sm:leading-loose tracking-tight">
                  토지
                </th>
                <td className="font table-td w-3/5 tracking-tight">700평</td>
              </tr>
              <tr className="border-b-2">
                <th className="font-bold table-th w-2/5 leading-loose sm:leading-loose tracking-tight">
                  건물
                </th>
                <td className="font table-td w-3/5 tracking-tight">400평</td>
              </tr>
              <tr className="">
                <th className="font-bold table-th w-2/5 leading-loose sm:leading-loose tracking-tight">
                  ALL
                </th>
                <td className="font table-td w-3/5 tracking-tight uppercase">
                  CNC WIRE BENDING
                </td>
              </tr>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};
