import React, { useState } from "react";
import { FOOTER } from "../../layout/footer";
import { SUBHEADER } from "../../layout/subheader";
import { Vision } from "../../parts/intro/vision";
import { Mission } from "../../parts/intro/mission";
import { CoreValue } from "../../parts/intro/coreValue";
export const SUMMARY = () => {
  const [summary, setsummary] = useState<number>(0);
  const summaryHandler = (num: number) => {
    setsummary(() => {
      return num;
    });
  };
  return (
    <main id="content">
      <SUBHEADER />
      <div id="intro" className="max-w-screen-lg w-full pt-32 sm:pt-64 m-auto">
        <h3 className="text-5xl sm:text-6xl font-bold text-center">
          <svg
            viewBox="0 0 75 74"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="headerGuard inline-block mr-10 sm:mr-16"
          >
            <path d="M1.5 73L49.5 1H73.5L23.5408 73H1.5Z" stroke="#40AEB8" />
          </svg>

          <span className="leading-normal esamanru font-bold text-main">
            기업개요
          </span>
          <svg
            viewBox="0 0 73 72"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="headerGuard inline-block ml-10 sm:ml-16"
          >
            <path d="M0.5 72L48.5 0H72.5L22.5408 72H0.5Z" fill="#0B2152" />
          </svg>
        </h3>
        <ul className="recruit_border pt-32 sm:pt-64">
          <li
            className={`box-border text-3xl sm:text-4xl align-middle inline-block w-1/3 h-32 sm:h-28 ${
              summary == 0 && "selectList"
            }`}
          >
            <button className="w-full h-full" onClick={() => summaryHandler(0)}>
              <a
                href="#Vision"
                className="w-full h-full flex flex-wrap content-center justify-center"
              >
                비전
              </a>
            </button>
          </li>
          <li
            className={`box-border text-3xl sm:text-4xl align-middle inline-block w-1/3 h-32 sm:h-28 ${
              summary == 1 && "selectList"
            }`}
          >
            <button className="w-full h-full" onClick={() => summaryHandler(1)}>
              <a
                href="#Mission"
                className="w-full h-full flex flex-wrap content-center justify-center"
              >
                미션
              </a>
            </button>
          </li>
          <li
            className={`box-border text-3xl sm:text-4xl align-middle inline-block w-1/3 h-32 sm:h-28 ${
              summary == 2 && "selectList"
            }`}
          >
            <button className="w-full h-full" onClick={() => summaryHandler(2)}>
              <a
                href="#CoreValue"
                className="w-full h-full flex flex-wrap content-center justify-center"
              >
                핵심가치
              </a>
            </button>
          </li>
        </ul>
        <Vision />
        <Mission />
        <CoreValue />
      </div>
      <FOOTER />
    </main>
  );
};
