import { divide, url } from "../../apollo";
import { FOOTER } from "../../layout/footer";
import { SUBHEADER } from "../../layout/subheader";
import { MainDev } from "../../parts/recruit/mainDev";
export const LABGOAL = () => {
  return (
    <main id="content">
      <SUBHEADER />
      <div id="recruit">
        <MainDev />
      </div>
      <FOOTER />
    </main>
  );
};
