import "../css/recruit.css";
import vision_logo from "../../assets/icon/vision_logo.png";
export const Vision = () => {
  return (
    <div className="pt-32 sm:pt-64" id="Vision">
      <p className="font-bold text-5xl text-center esamanru text-main uppercase">
        vision
      </p>
      <div className="headBar bg-main2 m-auto mt-11"></div>
      <div className="max-w-screen-md mx-auto pt-32 sm:pt-64 space-y-14 pb-6 px-6">
        <img src={vision_logo} alt="명보로고" className="mx-auto" />
        <p className="py-6 text-center font-bold esamanru text-main text-4xl sm:text-6xl">
          Creative & New technology
        </p>
        <p className="py-6 text-center esamanruM text-main text-4xl sm:text-6xl">
          미래기술 창조기업
        </p>
        <p className="py-6 text-center font-bold text-3xl sm:text-5xl leading-normal">
          ㈜명보는 '미래기술 창조기업'을 모토로
          <br />
          지속가능한 미래 기술을 통해 창조기업으로 거듭날 것입니다.
        </p>
      </div>
    </div>
  );
};
