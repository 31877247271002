import subHeader1_1 from "../assets/images/subheader/subHeader1_1.png";
import subHeader1_2 from "../assets/images/subheader/subHeader1_2.png";
import subHeader1_3 from "../assets/images/subheader/subHeader1_3.png";
import subHeader1_4 from "../assets/images/subheader/subHeader1_4.png";
import subHeader1_5 from "../assets/images/subheader/subHeader1_5.png";
import subHeader2_1 from "../assets/images/subheader/subHeader2_1.png";
import subHeader2_2 from "../assets/images/subheader/subHeader2_2.png";
import subHeader2_3 from "../assets/images/subheader/subHeader2_3.png";
import subHeader2_4 from "../assets/images/subheader/subHeader2_4.png";
import subHeader2_5 from "../assets/images/subheader/subHeader2_5.png";
import subHeader3_1 from "../assets/images/subheader/subHeader3_1.png";
import subHeader3_2 from "../assets/images/subheader/subHeader3_2.png";
import subHeader4_1 from "../assets/images/subheader/subHeader4_1.png";
import subHeader4_2 from "../assets/images/subheader/subHeader4_1.png";

// HEADER
export const menu = [
  {
    main: "회사소개",
    sub: [
      { menus: "기업개요", subback: subHeader1_1, links: "/intro/summary" },
      { menus: "CEO 인사말", subback: subHeader1_2, links: "/intro/comment" },
      { menus: "공장 현황", subback: subHeader1_3, links: "/intro/factory" },
      { menus: "연혁", subback: subHeader1_4, links: "/intro/history" },
      { menus: "조직도", subback: subHeader1_5, links: "/intro/organization" },
    ],
    mainLink: "/intro/summary",
    index: 0,
  },
  {
    main: "사업소개",
    sub: [
      { menus: "제품소개", subback: subHeader2_1, links: "/business/products" },
      { menus: "제조설비", subback: subHeader2_2, links: "/business/facility" },
      { menus: "생산라인", subback: subHeader2_3, links: "/business/line" },
      {
        menus: "계측기현황",
        subback: subHeader2_4,
        links: "/business/measuring",
      },

      {
        menus: "인증현황",
        subback: subHeader2_5,
        links: "/business/certification",
      },
    ],
    mainLink: "/business/products",
    index: 1,
  },
  {
    main: "R&D",
    sub: [
      {
        menus: "연구소 안내",
        subback: subHeader3_1,
        links: "/recruit/labIntro",
      },
      { menus: "연구 분야", subback: subHeader3_2, links: "/recruit/labGoal" },
      {
        menus: "연구개발 실적",
        subback: subHeader3_2,
        links: "/recruit/labPerform",
      },
    ],
    mainLink: "/recruit/labIntro",
    index: 2,
  },
  {
    main: "오시는 길",
    sub: [
      { menus: "오시는 길", subback: subHeader4_1, links: "/map/waytocome" },
      { menus: "Contact Us", subback: subHeader4_2, links: "/map/contactUs" },
    ],
    index: 3,
    mainLink: "/map/waytocome",
  },
];
