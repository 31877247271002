import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import * as React from "react";
import { HOME } from "../page/home";
import { HISTORY } from "../page/intro/intro_history";
import { CERTIFICATION } from "../page/intro/intro_certification";
import { ORGANIZATION } from "../page/intro/intro_organization";
import { LINE } from "../page/business/busi_line";
import { PRODUCTS } from "../page/business/busi_products";
import { FACILITY } from "../page/business/busi_facility";
import { SUMMARY } from "../page/intro/intro_summary";
import { COMMENT } from "../page/intro/intro_comment";
import { WORKPLACE } from "../page/workplace";
import { LABINTRO } from "../page/recruit/laboratory_introduce";
import { LABGOAL } from "../page/recruit/laboratory_result";
import { MEASURING } from "../page/business/busi_measuring";
import { FACTORY } from "../page/intro/intro_factory";
import { INQUIRY } from "../parts/map/Inquiry";
import { LABPERFORM } from "../page/recruit/laboratory_perform";
const Root: React.FC = () => (
  <Router>
    <Switch>
      <Route path="/" exact component={HOME} />
      <Route path="/intro/history" component={HISTORY} />
      <Route path="/intro/factory" component={FACTORY} />
      <Route path="/intro/organization" component={ORGANIZATION} />
      <Route path="/intro/summary/" component={SUMMARY} />
      <Route path="/intro/comment/" component={COMMENT} />
      <Route path="/intro/workplace/" component={WORKPLACE} />
      <Redirect path="/intro" to="/intro/summary" />
      <Route path="/business/line" component={LINE} />
      <Route path="/business/products" component={PRODUCTS} />
      <Route path="/business/facility" component={FACILITY} />
      <Route path="/business/certification" component={CERTIFICATION} />
      <Route path="/business/measuring" component={MEASURING} />
      <Redirect path="/business" to="/business/management" />
      <Route path="/recruit/labGoal" component={LABGOAL} />
      <Route path="/recruit/labIntro" component={LABINTRO} />
      <Route path="/recruit/labPerform" component={LABPERFORM} />
      <Redirect path="/recruit" to="/recruit/labIntro" />
      <Route path="/map/waytocome" component={WORKPLACE} />
      <Route path="/map/contactUs" component={INQUIRY} />
      <Redirect path="/map" to="/map/waytocome" />
    </Switch>
  </Router>
);

export default Root;
