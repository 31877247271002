import "../css/intro.css";
import mission from "../../assets/images/intro/mission.png";
import mission1_mobile from "../../assets/images/intro/mission1_mobile.png";
import mission2_mobile from "../../assets/images/intro/mission2_mobile.png";
import mission3_mobile from "../../assets/images/intro/mission3_mobile.png";
export const Mission = () => {
  return (
    <div className="pt-40 sm:pt-80" id="Mission">
      <p className="font-bold text-5xl text-center esamanru text-main uppercase">
        mission
      </p>
      <div className="headBar bg-main2 m-auto mt-11"></div>
      <div className="max-w-screen-lg mx-auto pt-32 sm:pt-64 px-6">
        <img
          src={mission}
          alt="미션: 최대의 만족실현 , 최선의 제품제공, 최대의 품질확보"
          className="mx-auto hidden sm:block"
        />
        <div className="block sm:hidden px-4 space-y-28">
        <img
          src={mission1_mobile}
          alt="미션: 최대의 만족실현 , 최선의 제품제공, 최대의 품질확보"
          className="mx-auto"
        /><img
        src={mission2_mobile}
        alt="미션: 최선의 제품제공"
        className="mx-auto"
      /><img
      src={mission3_mobile}
      alt="미션: 최대의 품질확보"
      className="mx-auto"
    />
        </div>
      </div>
    </div>
  );
};
