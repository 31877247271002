import "./layout.css";
import * as func from "./lay_func";
import { HEADER } from "./header";
import home from "../assets/icon/home.png";
import { useEffect, useState } from "react";
import { divide, url } from "../apollo";
import { useReactiveVar } from "@apollo/client";
import { useHistory } from "react-router";
import {
  createTheme,
  FormControl,
  MenuItem,
  Select,
  ThemeProvider,
} from "@mui/material";
import { left } from "@popperjs/core";
import { makeStyles } from "@material-ui/styles";

const theme = createTheme({
  typography: {
    fontFamily: '"pretendardR", "sans-serfi"',
  },
  palette: {
    background: {
      default: "#ccffff",
      paper: "#ffffff",
    },
    text: {
      primary: "#ffffff",
      secondary: "#000000",
    },
    primary: {
      main: "#0b2152",
    },
  },
});

const useStyles = makeStyles(() => ({
  select: {
    // background: "#F5F6F9",
    // paddingLeft: 24,
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

export const SUBHEADER = (props: any) => {
  var pathname = useReactiveVar(url);
  if (pathname.split("/")[2] == "") {
    pathname = func.menu[divide(pathname).mainIndex].sub[0].links;
  }
  const classes = useStyles();
  // handler
  const [open, setmenu] = useState<boolean>(true);
  const [subopen, setSubmenu] = useState<boolean>(true);
  let arrow1, arrow2;

  const submenuHandler = () => {
    setSubmenu(() => {
      if (subopen === false) return true;
      return false;
    });
  };
  const menuHandler = () => {
    setmenu(() => {
      if (open === false) return true;
      return false;
    });
  };
  if (open) {
    arrow1 = "▼";
  } else {
    arrow1 = "▲";
  }
  if (subopen) {
    arrow2 = "▼";
  } else {
    arrow2 = "▲";
  }

  const [stick, setStick] = useState<boolean>(false);
  document.addEventListener("scroll", () => {
    if (window.scrollY > 365) {
      setStick(true);
    } else {
      setStick(false);
    }
  });
  // css

  const subback = {
    backgroundImage: "url(" + divide(pathname).subback + ")",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  };
  const snbbtn = {
    width: "23.7rem",
  };

  return (
    <div>
      <HEADER />
      <div className="subBox">
        <div className="headerBlack"></div>
        <div className="sub_back relative text-white" style={subback}>
          <div className="w-full h-full flex flex-wrap flex-col justify-center text-center">
            <h2 className="text-5xl font-bold">{divide(pathname).mainValue}</h2>
            <p className="text-4xl mt-11 px-5">Creative & New technology</p>
          </div>
          <div
            id="SNB"
            className={`${
              stick ? "fixed left-0 top-32 z-20" : ""
            } w-full h-28 `}
          >
            <div className="m-auto max-w-screen-lg h-full text-center lg:flex block lg:flex-wrap lg:content-center">
              <div className="w-28 h-full hidden sm:inline-flex  flex-wrap content-center linebar relative">
                <a href="/" className="w-8 block">
                  <img src={home} alt="홈으로가기 버튼" className="w-4/5" />
                </a>
              </div>
              <div className="h-full sm:inline-flex flex-wrap w-96 content-center linebar relative sm:blcok hidden">
                {/* <div className="w-full h-full box-border relative">
                  <ThemeProvider theme={theme}>
                    <FormControl sx={{ width: 1, height: 1 }}>
                      <Select
                        value={divide(pathname).mainValue}
                        displayEmpty
                        sx={{
                          height: 1,
                          textAlign: left,

                          color: "text.primary",
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "0 none",
                          },
                        }}
                      >
                        {func.menu.map((obj) => (
                          <MenuItem
                            value={obj.main}
                            sx={{
                              color: "text.secondary",

                              height: 60,
                              "&:hover": {
                                backgroundColor: "Background.default",
                                color: "primary.main",
                              },
                            }}
                          >
                            <a
                              href={obj.mainLink}
                              className="text-3xl w-full h-full hover:font-bold  flex-wrap flex content-center justify-center"
                            >
                              {obj.main}
                            </a>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </ThemeProvider>
                </div> */}

                <button
                  className="w-full h-full box-border px-10 relative "
                  onClick={menuHandler}
                >
                  <span className="float-left text-3xl">
                    {divide(pathname).mainValue}
                  </span>
                  <span className="float-right text-2xl">{arrow1}</span>
                  <ul
                    className={`smm absolute top-28 left-0 w-full text-center bg-white border ${
                      open && "hidden"
                    }`}
                  >
                    {func.menu.map((obj) => (
                      <li className={`block h-24 w-full`} key={obj.main}>
                        <a
                          href={obj.mainLink}
                          className="text-3xl w-full h-full text-black hover:font-bold hover:text-main1 hover:bg-gray-200 flex-wrap flex content-center justify-center"
                        >
                          {obj.main}
                        </a>
                      </li>
                    ))}
                  </ul>
                </button>
              </div>
              {func.menu[divide(pathname).mainIndex].sub.length > 0 && (
                <div className="h-full sm:inline-flex inline-block w-full sm:w-96 flex-wrap content-center linebar relative">
                  <button
                    className={`w-full h-full box-border px-20 sm:px-10 relative ${
                      subopen ? "border-b-text" : "border-b-button"
                    }`}
                    onClick={submenuHandler}
                  >
                    <span className="sm:float-left text-center text-3xl">
                      {divide(pathname).subValue}
                    </span>
                    <span className="float-right text-2xl">{arrow2}</span>
                    <ul
                      className={`sms absolute left-0 top-28 w-full text-center bg-white border z-10 shadow md:shadow-none ${
                        subopen && "hidden"
                      }`}
                    >
                      {func.menu[divide(pathname).mainIndex].sub.length > 0 &&
                        func.menu[divide(pathname).mainIndex].sub.map((obj) => (
                          <li
                            className={`block h-24 w-full `}
                            key={`${obj.menus}sub`}
                          >
                            <a
                              href={obj.links}
                              className="text-3xl w-full h-full text-black hover:font-bold hover:text-main1 hover:bg-gray-200 flex-wrap flex content-center justify-center"
                            >
                              {obj.menus}
                            </a>
                          </li>
                        ))}
                    </ul>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
