import React from "react";
import { measurment } from "./parts";
import shape from "../../assets/icon/Frame.png";
import { Fade } from "react-awesome-reveal";
export const Measuring = () => {
  return (
    <div className="max-w-screen-lg w-full px-6 pb-40 sm:pb-80 pt-32 sm:pt-64 m-auto">
      <h3 className="text-5xl sm:text-6xl font-bold text-center">
        <svg
          viewBox="0 0 75 74"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="headerGuard inline-block mr-10 sm:mr-16"
        >
          <path d="M1.5 73L49.5 1H73.5L23.5408 73H1.5Z" stroke="#40AEB8" />
        </svg>

        <span className="leading-normal esamanru font-bold text-main">
          계측기현황
        </span>
        <svg
          viewBox="0 0 73 72"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="headerGuard inline-block ml-10 sm:ml-16"
        >
          <path d="M0.5 72L48.5 0H72.5L22.5408 72H0.5Z" fill="#0B2152" />
        </svg>
      </h3>
      <div className="pt-32 sm:pt-64 wrap flex flex-wrap max-w-screen-lg mx-auto justify-between sm:justify-start sm:gap-x-16 gap-y-24">
        <Fade className="measure" triggerOnce={true}>
          {measurment.map((measurment, index) => (
            <div className="border border-gray-200">
              <p className="text-center text-3xl lg:text-4xl text-main1 pt-5">
                {measurment.name}
              </p>
              <div className="bg-main2 w-36 h-1 m-auto my-5 sm:my-10"></div>
              <div className="">
                <div className="w-full paper">
                  <img
                    src={measurment.image}
                    alt="계측기 이미지"
                    className=""
                  />
                </div>
                <p className="text-right pr-3 text-3xl pt-2 ">
                  {measurment.maker}
                </p>
              </div>
            </div>
          ))}
        </Fade>
      </div>
      {/* <div className="pt-32 sm:pt-64 hidden sm:block">
        <table className="text-center w-full measurTable text-3xl sm:text-4xl keep-all">
          <tr>
            <th></th>
            <th>계측기</th>
            <th>메이커</th>
          </tr>
          {measurment.map((measurment, index) => (
            <tr key={index + " " + measurment.name}>
              <td>
                <img src={measurment.image} alt={measurment.name + "이미지"} />
              </td>
              <td>{measurment.name}</td>
              <td>{measurment.maker}</td>
            </tr>
          ))}
        </table>
      </div>
      <div className="pt-32 sm:pt-64 block sm:hidden">
        <table className="text-center w-full measurTable text-3xl sm:text-4xl keep-all">
          {measurment.map((measurment, index) => (
            <tr>
              <td>
                {" "}
                <img src={measurment.image} alt={measurment.name + "이미지"} />
                <p className="pt-8 text-left pl-32">
                  계측기 : {measurment.name}
                </p>
                <p className="pt-8 text-left pl-32">
                  메이커 : {measurment.maker}
                </p>
              </td>
            </tr>
          ))}
        </table>
      </div> */}
    </div>
  );
};
