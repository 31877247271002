import line2 from "../../assets/images/business/Factory_2_vector.png";

import { Fade } from "react-awesome-reveal";
import "../css/business.css";
export const AutoLine = () => {
  return (
    <div className="">
      <Fade delay={300} triggerOnce={true}>
        <div id="autostack" className="">
          <div className="stt text-center pb-32 sm:pb-64">
            <p className="font-bold text-5xl text-center esamanru text-main uppercase">
              Auto Diecasting cable Line
            </p>
            <div className="headBar bg-main2 m-auto mt-11"></div>
          </div>
          <div className="grid grid-cols-1 tranking-tight">
            <img src={line2} alt="이미지" className="w-full pb-32 sm:pb-64" />
            <table className=" info border-t-2 border-b-2 w-full border-gray-700">
              <tr className="border-b-2">
                <th className="font-bold table-th w-2/5 leading-loose sm:leading-loose tracking-tight">
                  차종
                </th>
                <td className="font table-td w-3/5 tracking-tight">공용</td>
              </tr>
              <tr className="border-b-2">
                <th className="font-bold table-th w-2/5 leading-loose sm:leading-loose tracking-tight">
                  생산 capa
                </th>
                <td className="font table-td w-3/5 tracking-tight">
                  10,000,000 EA / 년
                </td>
              </tr>
              <tr className="border-b-2">
                <th className="font-bold table-th w-2/5 leading-loose sm:leading-loose tracking-tight">
                  양산일
                </th>
                <td className="font table-td w-3/5 tracking-tight">
                  2021.08월
                </td>
              </tr>
              <tr className="border-b-2">
                <th className="font-bold table-th w-2/5 leading-loose sm:leading-loose tracking-tight">
                  설비명
                </th>
                <td className="font table-td w-3/5 tracking-tight uppercase">
                  자동 인서트 다이캐스팅 라인
                </td>
              </tr>
              <tr className="border-b-2">
                <th className="font-bold table-th w-2/5 leading-loose sm:leading-loose tracking-tight">
                  설비 구성
                </th>
                <td className="font table-td w-3/5 tracking-tight">
                  5스텝 / 협동로봇 2대
                </td>
              </tr>
              <tr className="">
                <th className="font-bold table-th w-2/5 leading-loose sm:leading-loose tracking-tight">
                  특징
                </th>
                <td className="font table-td w-3/5 tracking-tight">
                  <p>1. 자체 전용라인으로 셋업</p>
                  <p>2. 모든 stage Fool proof 적용</p>
                  <p>3. 프로그램별 불러내기</p>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </Fade>
    </div>
  );
};
