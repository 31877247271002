import factory from "../../assets/images/intro/factory2.png";
export const MainOffice = () => {
  return (
    <div className="pb-40 sm:pb-80">
      <div className="max-w-screen-lg m-auto pb-32 sm:pb-64">
        <div className="mapRatio relative">
          <iframe
            className="w-full h-full absolute left-0 top-0"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3239.485225067408!2d129.29640001560355!3d35.71428338018666!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x35664ae7e80732ad%3A0xb81b71f93623da84!2z6rK97IOB67aB64-EIOqyveyjvOyLnCDsmbjrj5nsnY0g64K07Jm466GcIDI4NC01!5e0!3m2!1sko!2skr!4v1667796880053!5m2!1sko!2skr"
          ></iframe>
        </div>
      </div>
      {/* <img
        src={factory}
        alt="명보 본사 이미지"
        className="w-full pb-32 sm:pb-64"
      /> */}
      <table className=" info border-t-2 border-b-2 w-full border-gray-700 ">
        <tr className="border-b-2">
          <th className="font-bold table-th w-2/5 leading-loose sm:leading-loose tracking-tight">
            설립연월일
          </th>
          <td className="font table-td w-3/5 tracking-tight">2010.03</td>
        </tr>
        <tr className="border-b-2">
          <th className="font-bold table-th w-2/5 leading-loose sm:leading-loose tracking-tight">
            업종
          </th>
          <td className="font table-td w-3/5 tracking-tight">자동차 부품</td>
        </tr>
        <tr className="border-b-2">
          <th className="font-bold table-th w-2/5 leading-loose sm:leading-loose tracking-tight">
            공장규모
          </th>
          <td className="font table-td w-3/5 tracking-tight">
            토지 2,700평 / 건평 700평
          </td>
        </tr>
        <tr className="border-b-2">
          <th className="font-bold table-th w-2/5 leading-loose sm:leading-loose tracking-tight">
            주요품목
          </th>
          <td className="font table-td w-3/5 tracking-tight uppercase">
            자동차 시트
          </td>
        </tr>
        <tr className="border-b-2">
          <th className="font-bold table-th w-2/5 leading-loose sm:leading-loose tracking-tight">
            주거래처
          </th>
          <td className="font table-td w-3/5 tracking-tight">
            (주)디에스시 外
          </td>
        </tr>
        <tr className="border-b-2">
          <th className="font-bold table-th w-2/5 leading-loose sm:leading-loose tracking-tight">
            주소
          </th>
          <td className="font table-td w-3/5 tracking-tight">
            경주시 외동읍 내외로 284-5
          </td>
        </tr>
        <tr className="border-b-2">
          <th className="font-bold table-th w-2/5 leading-loose sm:leading-loose tracking-tight">
            연락처
          </th>
          <td className="font table-td w-3/5 tracking-tight">054-777-0086</td>
        </tr>
        <tr>
          <th className="font-bold table-th w-2/5 leading-loose sm:leading-loose tracking-tight">
            메일
          </th>
          <td className="font table-td w-3/5 tracking-tight">
            <a
              href="mailTo:sman017@naver.com"
              className="text-main2 hover:underline"
            >
              sman017@naver.com
            </a>
          </td>
        </tr>
      </table>
    </div>
  );
};
