import "./layout.css";
import logo_white from "../assets/icon/logofot_mb.png";
export const FOOTER = () => {
  return (
    <footer className="w-full h-300 box-border flex flex-wrap content-center  sm:justify-center flex-col bg-footer py-10 sm:py-0 sm:gap-y-10 gap-y-8 text-white px-10">
      <div className="text-center">
        <a href="/" className="footer_logo inline-block h-24">
          <img
            src={logo_white}
            alt="명보 로고"
            className="h-full inline-block"
          />
        </a>
      </div>
      <address className="text-xl sm:text-2xl not-italic text-left sm:text-center sm:space-y-0 space-y-2">
        <span className="afterbar block sm:inline">
          사업자번호 : 505-81-56712
        </span>
        <span className="afterbar block sm:inline">
          전화번호 : 054-777-0086
        </span>

        <span className="afterbar block sm:inline">
          메일 : sman017@naver.com
        </span>
        <span className="block sm:inline">
          주소 : 경주시 외동읍 내외로 284-5
        </span>
      </address>
      <small className="text-center text-xl sm:text-2xl">
        <span className="capitalize">copyright</span>&copy; ㈜명보.
        <span className="capitalize">all</span> rights reserved.
      </small>
    </footer>
  );
};
