import React, { useState } from "react";
import { Fade } from "react-awesome-reveal";
import lab4 from "../../assets/images/labratory/lab4.png";
import cer1 from "../../assets/images/labratory/cer1.png";
import cer2 from "../../assets/images/labratory/cer2.png";
import cer3 from "../../assets/images/labratory/cer3.png";
import cer4 from "../../assets/images/labratory/cer4.png";
import cer5 from "../../assets/images/labratory/cer5.jpg";
import cer6 from "../../assets/images/labratory/cer6.png";
import cer7 from "../../assets/images/labratory/cer7.png";
import cer8 from "../../assets/images/labratory/cer8.png";
import "../css/recruit.css";
export const MainDev = () => {
  const texts = [
    {
      para: "전기차용 열폭주 방지 버스바",
    },
    {
      para: "배터리 냉각호스",
    },
    {
      para: "에어 솔레노이드 밸브",
    },
    {
      para: "에어포켓 융착",
    },
  ];
  return (
    <div className="max-w-screen-xl m-auto pb-40 sm:pb-80 pt-32 sm:pt-64 px-6">
      <h3 className="text-5xl sm:text-6xl font-bold text-center">
        <svg
          viewBox="0 0 75 74"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="headerGuard inline-block mr-10 sm:mr-16"
        >
          <path d="M1.5 73L49.5 1H73.5L23.5408 73H1.5Z" stroke="#40AEB8" />
        </svg>

        <span className="leading-normal esamanru font-bold text-main">
          연구분야
        </span>
        <svg
          viewBox="0 0 73 72"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="headerGuard inline-block ml-10 sm:ml-16"
        >
          <path d="M0.5 72L48.5 0H72.5L22.5408 72H0.5Z" fill="#0B2152" />
        </svg>
      </h3>
      <div id="mainDev" className="pt-32 sm:pt-64 px-6">
        <p className="font-bold text-3xl sm:text-5xl text-center esamanru text-main uppercase">
          연구 개발 예정품목
        </p>
        <div className="headBar bg-main2 m-auto mt-11"></div>
        <div className="pt-32 sm:pt-64 max-w-screen-lg mx-auto ">
          <img src={lab4} alt="인장테스트기" className="mx-auto imagebox" />
        </div>
        <div className="pt-32 sm:pt-64 max-w-7xl mx-auto space-y-16">
          {texts.map((lists, index) => (
            <div className="relative listContainer" key={`lists` + index}>
              <p className="pl-24 sm:pl-48 listBorder flex flex-wrap content-center text-3xl sm:text-4xl font-bold keep-all">
                {lists.para}
              </p>
              <div className="listNumberBall text-white flex flex-wrap content-center justify-center text-4xl">
                {index + 1}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
