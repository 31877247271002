import cable1 from "../../assets/images/business/part/01/seat_cable.jpg";
import cable2 from "../../assets/images/business/part/01/remote_cable.jpg";
import cable3 from "../../assets/images/business/part/01/ratch_cable.jpg";
import cable4 from "../../assets/images/business/part/01/bowden_cable.jpg";
import seat1 from "../../assets/images/business/part/02/mb-seat.png";
import seat2 from "../../assets/images/business/part/02/mb-seat-2.png";
import seat3 from "../../assets/images/business/part/02/mb-seat-3.png";
import seat4 from "../../assets/images/business/part/02/mb-seat-4.png";
import seat5 from "../../assets/images/business/part/02/mb-seat-5.png";
import measur1 from "../../assets/images/business/충격시험계.jpg";
import measur2 from "../../assets/images/business/정밀정반.jpg";
import measur3 from "../../assets/images/business/인장압축시험기.jpg";
import measur4 from "../../assets/images/business/디지털버니어.png";
import measur5 from "../../assets/images/business/디지털토크측정기.png";
import measur6 from "../../assets/images/business/디지털푸쉬풀게이지.png";
import measur7 from "../../assets/images/business/디지털온도계.png";
import measur8 from "../../assets/images/business/외측마이크로미터.jpg";
import measur9 from "../../assets/images/business/소음진동검사기.png";
export const measurment = [
  {
    image: measur1,
    name: "충격시험계",
    maker: "센서솔루션",
  },
  { image: measur2, name: "정밀정반", maker: "한도" },
  { image: measur3, name: "인장압축시험기", maker: "대성" },
  { image: measur4, name: "디지털 버니어", maker: "MITUTOYO" },
  { image: measur5, name: "디지털 토크", maker: "CEDAR" },
  { image: measur6, name: "디지털 푸쉬폴", maker: "SHITO" },
  { image: measur7, name: "디지털 온도계", maker: "CENTER" },
  { image: measur8, name: "외측 마이크로미터", maker: "MITUTOYO" },
  { image: measur9, name: "소음 진동검사기", maker: "MITUTOYO" },
];

export const Cable = [
  {
    code: "0-1",
    name: "seat cable",
    path: cable1,
  },
  {
    code: "0-2",
    name: "remote cable",
    path: cable2,
  },
  {
    code: "0-3",
    name: "ratch cable",
    path: cable3,
  },
  {
    code: "0-4",
    name: "bowden cable",
    path: cable4,
  },
];
export const Seat = [
  {
    code: "1-1",
    name: "mb-seat",
    path: seat1,
  },
  {
    code: "1-2",
    name: "mb-seat2",
    path: seat2,
  },
  {
    code: "1-3",
    name: "mb-seat3",
    path: seat3,
  },
  {
    code: "1-4",
    name: "mb-seat4",
    path: seat4,
  },
  {
    code: "1-5",
    name: "mb-seat5",
    path: seat5,
  },
];
export const Etc = [
  {
    code: "2-1",
    name: "기타1",
    path: "",
  },
  {
    code: "2-2",
    name: "기타2",
    path: "",
  },
  {
    code: "2-3",
    name: "기타3",
    path: "",
  },
  {
    code: "2-4",
    name: "기타",
    path: "",
  },
];
