import { useState } from "react";
import { FOOTER } from "../../layout/footer";
import { SUBHEADER } from "../../layout/subheader";
export const COMMENT = () => {
  return (
    <main id="content">
      <SUBHEADER />
      <div
        id="intro"
        className="max-w-screen-lg w-full px-6 pt-32 sm:pt-64 m-auto"
      >
        <h3 className="text-5xl sm:text-6xl font-bold text-center">
          <svg
            viewBox="0 0 75 74"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="headerGuard inline-block mr-6 sm:mr-16"
          >
            <path d="M1.5 73L49.5 1H73.5L23.5408 73H1.5Z" stroke="#40AEB8" />
          </svg>

          <span className="leading-normal esamanru font-bold text-main">
            CEO인사말
          </span>
          <svg
            viewBox="0 0 73 72"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="headerGuard inline-block ml-6 sm:ml-16"
          >
            <path d="M0.5 72L48.5 0H72.5L22.5408 72H0.5Z" fill="#0B2152" />
          </svg>
        </h3>
        <div className="pt-32 sm:pt-64">
          <p className="font-bold text-4xl sm:text-5xl text-center esamanru text-main uppercase">
            Always with U...
          </p>
          <div className="headBar bg-main2 m-auto mt-11 mb-10"></div>
          <p className="text-center font-bold text-4xl sm:text-5xl esamanru">
            더 나은 미래를 위해!
          </p>
        </div>

        <div className="pt-32 sm:pt-64 md:px-10">
          <div className="relative mainText pb-40 sm:pb-80">
            <div className="texts pt-9 pb-12 space-y-12 text-2xl sm:text-4xl leading-normal sm:leading-normal">
              <p>
                안녕하세요.
                <br />
                ㈜명보 대표이사 <span className="font-bold">권도영</span>입니다.
              </p>
              <p>
                대한민국 자동차 부품산업의 퍼스트 무버!
                <br />
                ㈜명보 홈페이지 방문을 진심으로 감사드립니다.
              </p>
              <p>
                ㈜명보는 지난 <span className="font-bold">2009</span>년 설립
                이래,
                <br />
                다년간 축적된 독보적인 기술력과 노하우를 바탕으로
                <br />
                급변하는 모빌리티 산업 트렌드에 즉각적으로 대응해왔습니다.
              </p>
              <p>
                또한 꾸준한 설비투자 및 기술개발을 필두로
                <br />
                <span className="font-bold">
                  럼버서포트(OEM)와 컨트롤케이블 &nbsp;
                </span>
                등 완성차의 필수요인 다양한 부품을 전문적으로 생산하며,
                <br />
                국내는 물론 세계시장에 이르기까지 영향력을 지속적으로
                확대해왔습니다.
              </p>
              <p>
                4차 산업혁명시대를 맞아
                <br />
                글로벌 모빌리티 산업은 또 한번 격변의 시기에 도래했습니다.
              </p>
              <p>
                수소자동차, 전기자동차 등<br />
                친환경 모빌리티의 약진과 함께
                <br />
                완성차에 사용되는 부품 역시 진화를 거듭하고 있습니다.
              </p>
              <p>
                ㈜명보 임직원 모두는
                <br />
                창의적 사고와 뜨거운 열정,
                <br />
                한 발 앞서 미래를 내다볼 수 있는 도전정신을 핵심가치로
                <br />
                고객 여러분들의 요구에 부합할 수 있도록 최선의 노력을 기울일
                것입니다.
              </p>
              <p>
                앞으로도 ㈜명보는
                <br />
                정직과 신뢰를 기반으로 스마트 정도경영을 실천하며
                <br />
                여러분의 든든한 동반자가 될 것을 약속드립니다.
              </p>
            </div>
            <div className="sign pt-24 sm:pt-64 text-right">
              <p className="text-2xl sm:text-4xl">
                ㈜명보 대표이사 <span className="font-bold">권도영</span> 배상
              </p>
            </div>
          </div>
        </div>
      </div>
      <FOOTER />
    </main>
  );
};
