import React, { useState } from "react";
import { FOOTER } from "../../layout/footer";
import { SUBHEADER } from "../../layout/subheader";
import lab1 from "../../assets/images/labratory/lab5.png";
import "swiper/swiper.scss";
import "../../parts/css/recruit.css";
export const LABINTRO = () => {
  return (
    <main id="content">
      <SUBHEADER />
      <div
        id="recruit"
        className="max-w-screen-lg m-auto pb-40 sm:pb-80 pt-32 sm:pt-64 px-6"
      >
        <h3 className="text-3xl sm:text-6xl font-bold text-center">
          <svg
            viewBox="0 0 75 74"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="headerGuard inline-block mr-10 sm:mr-16"
          >
            <path d="M1.5 73L49.5 1H73.5L23.5408 73H1.5Z" stroke="#40AEB8" />
          </svg>

          <span className="leading-normal esamanru font-bold text-main">
            연구소 안내
          </span>
          <svg
            viewBox="0 0 73 72"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="headerGuard inline-block ml-10 sm:ml-16"
          >
            <path d="M0.5 72L48.5 0H72.5L22.5408 72H0.5Z" fill="#0B2152" />
          </svg>
        </h3>
        <div className="pt-32 sm:pt-64 w-full max-w-screen-lg mx-auto">
          <p className=" text-3xl sm:text-5xl font-bold leading-tight1 sm:leading-tight1 p-8 sm:p-16 text-left sm:text-center keep-all">
            ㈜명보는 미래기술을 창조하는 기업으로 나아나고자 지난 2013년 분야별
            전문 인력으로 구성 된 기업부설 연구소를 설립, 카티아 및 캐드 등 전문
            프로그램 활용하여 신제품 설계부터 양산에 이르는 전 과정을
            원-스톱으로 처리하고 있습니다.
          </p>
        </div>
        <div className="pt-16 sm:pt-32 max-w-screen-lg mx-auto ">
          <img
            src={lab1}
            alt="인장테스트기"
            className="mx-auto imagebox w-full"
          />
        </div>
        <div className="max-w-screen-lg mx-auto grid grid-row-4 gap pt-32">
          <div className="flex flex-wrap">
            <p className="w-full bg-main1 text-center text-3xl sm:text-5xl py-6 font-bold">
              체계적 관리를 통한 품질확보
            </p>
            <p className="labB w-full"></p>
            <p className="labContent text-3xl sm:text-4xl keep-all leading-tight sm:leading-tight p-8 sm:p-16">
              비파괴검사 및 내구시험, 파괴검사 등을 중심으로 제조 부품의 품질
              확보에 집중하고 있으 며, 체계적인 전산 시스템 기반의 생산 및 유통
              이력 관리를 통해 공신력 구축에 최선을 다하 고 있습니다.
            </p>
          </div>
          <div className="flex flex-wrap">
            <p className="w-full bg-main1 text-center text-3xl sm:text-5xl py-6 font-bold">
              다수의 기술 특허 및 디자인 출원
            </p>
            <p className="labB w-full"></p>
            <p className="labContent text-3xl sm:text-4xl keep-all leading-tight sm:leading-tight p-8 sm:p-16">
              기업부설 연구소를 통해 기획, 개발된 설비 및 제품, 공법 등은 국내를
              비롯해 해외에 이르기까지 다수의 특허 및 디자인 출원을 확보하고
              있습니다.
            </p>
          </div>
          <div className="flex flex-wrap">
            <p className="w-full bg-main1 text-center text-3xl sm:text-5xl py-6 font-bold">
              정부 국책사업 통한 대외적 경쟁력 확보
            </p>
            <p className="labB w-full"></p>
            <p className="labContent text-3xl sm:text-4xl keep-all leading-tight sm:leading-tight p-8 sm:p-16">
              정부가 추진하는 다양한 국책사업 참여기관 선정 이력을 기반으로
              우수한 R&D 및 시제품 개 발 실적을 달성하고 있으며, 대외적 경쟁력을
              확보해나가고 있습니다
            </p>
          </div>
          <div className="flex flex-wrap">
            <p className="w-full bg-main1 text-center text-3xl sm:text-5xl py-6 font-bold">
              지속가능한 미래전략의 핵심
            </p>
            <p className="labB w-full"></p>
            <p className="labContent text-3xl sm:text-4xl keep-all leading-tight sm:leading-tight p-8 sm:p-16">
              기업부설 연구소는 글로벌 자동차 부품산업 선도기업을 목표로 하는
              ㈜명보의 핵심 부서이자 더 나은 미래로 나아가기 위한 베이스캠프로,
              필요 재원에 대한 투자와 지원을 아끼지 않고 있습니다.
            </p>
          </div>
        </div>
      </div>
      <FOOTER />
    </main>
  );
};
