import React from "react";
import { FOOTER } from "../../layout/footer";
import { SUBHEADER } from "../../layout/subheader";
import { History } from "../../parts/intro/history";
export const HISTORY = () => {
  return (
    <main id="content">
      <SUBHEADER />
      <div id="intro">
        <History />
      </div>
      <FOOTER />
    </main>
  );
};
