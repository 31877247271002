import organization from "../../assets/images/intro/organization.png";
import organization_mobile from "../../assets/images/intro/organization_mobile.png";
export const Organization = () => {
  return (
    <div className="max-w-screen-lg w-full px-6 pt-32 md:pt-64 pb-40 sm:pb-80 m-auto">
      <h3 className="text-4xl sm:text-6xl font-bold text-center">
        <svg
          viewBox="0 0 75 74"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="headerGuard inline-block mr-10 sm:mr-16"
        >
          <path d="M1.5 73L49.5 1H73.5L23.5408 73H1.5Z" stroke="#40AEB8" />
        </svg>

        <span className="leading-normal esamanru font-bold text-main">
          조직도
        </span>
        <svg
          viewBox="0 0 73 72"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="headerGuard inline-block ml-10 sm:ml-16"
        >
          <path d="M0.5 72L48.5 0H72.5L22.5408 72H0.5Z" fill="#0B2152" />
        </svg>
      </h3>
      <div className="wrap pt-32 sm:pt-64">
        <img src={organization} alt="조직도 이미지" className="w-full sm:block hidden" />
        <img src={organization_mobile} alt="조직도 이미지" className="w-full sm:hidden block" />
      </div>
    </div>
  );
};
