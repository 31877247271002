import React from "react";
import cer1 from "../../assets/images/business/certification1.jpg";
import cer2 from "../../assets/images/business/certification2.jpg";
import cer3 from "../../assets/images/business/certification3.jpg";
import cer4 from "../../assets/images/business/certification4.jpg";
import cer5 from "../../assets/images/business/certification5.jpg";
import cer6 from "../../assets/images/business/certification6.jpg";
import cer7 from "../../assets/images/business/certification7.jpg";
import cer8 from "../../assets/images/business/certification8.jpg";
import cer9 from "../../assets/images/business/certification9.jpg";
import { Fade } from "react-awesome-reveal";
export const Certification = () => {
  return (
    <div className="max-w-screen-xl w-full px-6 pb-40 sm:pb-80 pt-32 sm:pt-64 m-auto">
      <h3 className="text-5xl sm:text-6xl font-bold text-center">
        <svg
          viewBox="0 0 75 74"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="headerGuard inline-block mr-10 sm:mr-16"
        >
          <path d="M1.5 73L49.5 1H73.5L23.5408 73H1.5Z" stroke="#40AEB8" />
        </svg>

        <span className="leading-normal esamanru font-bold text-main">
          인증현황
        </span>
        <svg
          viewBox="0 0 73 72"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="headerGuard inline-block ml-10 sm:ml-16"
        >
          <path d="M0.5 72L48.5 0H72.5L22.5408 72H0.5Z" fill="#0B2152" />
        </svg>
      </h3>
      <div className="pt-32 sm:pt-64 wrap flex flex-wrap max-w-screen-lg mx-auto  justify-between gap-y-24">
        <Fade className="license" triggerOnce={true}>
          <div>
            <p className="text-center text-3xl lg:text-4xl text-main1">
              SQ 인증서(전기조립)
            </p>
            <div className="bg-main2 w-36 h-1 m-auto my-10"></div>
            <div className="w-full paper">
              <img
                src={cer1}
                alt="SQ 인증서"
                className="absolute w-full left-0 top-0 border"
              />
            </div>
          </div>
          <div>
            <p className="text-center text-3xl lg:text-4xl text-main1">
              SQ 인증서(주단조)
            </p>
            <div className="bg-main2 w-36 h-1 m-auto my-10"></div>
            <div className="w-full paper">
              <img
                src={cer2}
                alt="SQ 인증서"
                className="absolute w-full left-0 top-0 border"
              />
            </div>
          </div>
          <div>
            <p className="text-center text-3xl lg:text-4xl text-main1">
              메인비즈 확인서
            </p>
            <div className="bg-main2 w-36 h-1 m-auto my-10"></div>
            <div className="w-full paper">
              <img
                src={cer3}
                alt="메인비즈 확인서"
                className="absolute w-full left-0 top-0 border"
              />
            </div>
          </div>
          <div>
            <p className="text-center text-3xl lg:text-4xl text-main1">
              기업부설연구소 인증서
            </p>
            <div className="bg-main2 w-36 h-1 m-auto my-10"></div>
            <div className="w-full paper">
              <img
                src={cer4}
                alt="기업부설연구소 인증서"
                className="absolute w-full left-0 top-0 border"
              />
            </div>
          </div>
          <div>
            <p className="text-center text-3xl lg:text-4xl text-main1 h-10 sm:h-auto">
              벤처기업확인서
            </p>
            <div className="bg-main2 w-36 h-1 m-auto my-10"></div>
            <div className="w-full paper">
              <img
                src={cer5}
                alt="벤처기업확인서"
                className="absolute w-full left-0 top-0 border"
              />
            </div>
          </div>
          <div>
            <p className="text-center text-3xl lg:text-4xl text-main1 h-10 sm:h-auto">
              소재,부품,장비 전문기업확인서
            </p>
            <div className="bg-main2 w-36 h-1 m-auto my-10"></div>
            <div className="w-full paper">
              <img
                src={cer6}
                alt="소재,부품,장비 전문기업확인서"
                className="absolute w-full left-0 top-0 border"
              />
            </div>
          </div>
          <div>
            <p className="text-center text-3xl lg:text-4xl text-main1">
              여성기업 확인서
            </p>
            <div className="bg-main2 w-36 h-1 m-auto my-10"></div>
            <div className="w-full paper">
              <img
                src={cer7}
                alt="여성기업 확인서"
                className="absolute w-full left-0 top-0 border"
              />
            </div>
          </div>
          <div>
            <p className="text-center text-3xl lg:text-4xl text-main1">
              CERTIFICATE
            </p>
            <div className="bg-main2 w-36 h-1 m-auto my-10"></div>
            <div className="w-full paper">
              <img
                src={cer8}
                alt="CERTIFICATE"
                className="absolute w-full left-0 top-0 border"
              />
            </div>
          </div>
          <div>
            <p className="text-center text-3xl lg:text-4xl text-main1">
              CERTIFICATE
            </p>
            <div className="bg-main2 w-36 h-1 m-auto my-10"></div>
            <div className="w-full paper">
              <img
                src={cer9}
                alt="CERTIFICATE"
                className="absolute w-full left-0 top-0 border"
              />
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
};
