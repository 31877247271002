import React from "react";
import { FOOTER } from "../../layout/footer";
import { SUBHEADER } from "../../layout/subheader";
import { Organization } from "../../parts/intro/organization";
export const ORGANIZATION = () => {
  return (
    <main id="content">
      <SUBHEADER />
      <div id="intro">
        <Organization />
      </div>
      <FOOTER />
    </main>
  );
};
