import React from "react";
import { FOOTER } from "../../layout/footer";
import { SUBHEADER } from "../../layout/subheader";
import { Facility } from "../../parts/business/facility";
export const FACILITY = () => {
  return (
    <main id="content">
      <SUBHEADER />
      <div id="business">
        <Facility />
      </div>
      <FOOTER />
    </main>
  );
};
