import React, { useState } from "react";
import { FOOTER } from "../../layout/footer";
import { SUBHEADER } from "../../layout/subheader";
import { AutoLine } from "../../parts/business/Autoline";
import { LumbarLine } from "../../parts/business/lumbar";
export const LINE = () => {
  const [line, setLine] = useState<number>(0);
  const lineHandler = (num: number) => {
    setLine(() => {
      return num;
    });
  };

  return (
    <main id="content">
      <SUBHEADER />
      <div
        id="business"
        className="max-w-screen-lg mx-auto px-6 pb-40 sm:pb-80 pt-32 sm:pt-64"
      >
        <h3 className="text-5xl sm:text-6xl font-bold text-center">
          <svg
            viewBox="0 0 75 74"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="headerGuard inline-block mr-6 sm:mr-16"
          >
            <path d="M1.5 73L49.5 1H73.5L23.5408 73H1.5Z" stroke="#40AEB8" />
          </svg>

          <span className="leading-normal esamanru font-bold text-main">
            생산라인
          </span>
          <svg
            viewBox="0 0 73 72"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="headerGuard inline-block ml-6 sm:ml-16"
          >
            <path d="M0.5 72L48.5 0H72.5L22.5408 72H0.5Z" fill="#0B2152" />
          </svg>
        </h3>
        <ul className="recruit_border py-32 sm:py-64">
          <li
            className={`box-border text-3xl sm:text-4xl align-middle inline-block w-1/2 h-28 ${
              line == 0 && "selectList"
            }`}
          >
            <button
              className="w-full h-full flex flex-wrap content-center justify-center"
              onClick={() => lineHandler(0)}
            >
              Lumbar Support 조립 Line
            </button>
          </li>
          <li
            className={`box-border text-3xl sm:text-4xl align-middle inline-block w-1/2 h-28 ${
              line == 1 && "selectList"
            }`}
          >
            <button
              className="w-full h-full flex flex-wrap content-center justify-center"
              onClick={() => lineHandler(1)}
            >
              Auto Diecasting Cable Line
            </button>
          </li>
        </ul>
        {line == 0 && <LumbarLine />}
        {line == 1 && <AutoLine />}
      </div>
      <FOOTER />
    </main>
  );
};
