import React, { useState } from "react";
import "../css/business.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
import SwiperCore from "swiper/core";
import { facilityData } from "./facilities";
import "swiper/swiper.scss";
import back from "../../assets/icon/backArrow.png";
import { CableFacility } from "./cableFacility";
import { LumbarSupport } from "./LumbarSupport";
import { WireFacility } from "./WireFacility";
import { MeasureFacility } from "./measureFacility";
SwiperCore.use([Navigation, Pagination, Autoplay]);
export const Facility = () => {
  document.body.classList.add("smooth");
  const [tab, settab] = useState<number>(0);
  const settabHandler = (num: number) => {
    settab(() => {
      return num;
    });
  };
  return (
    <div className="max-w-screen-lg w-full px-6 pt-32 sm:pt-64 m-auto">
      <h3 className="text-5xl sm:text-6xl font-bold text-center">
        <svg
          viewBox="0 0 75 74"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="headerGuard inline-block mr-10 sm:mr-16"
        >
          <path d="M1.5 73L49.5 1H73.5L23.5408 73H1.5Z" stroke="#40AEB8" />
        </svg>

        <span className="leading-normal esamanru font-bold text-main">
          제조설비
        </span>
        <svg
          viewBox="0 0 73 72"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="headerGuard inline-block ml-10 sm:ml-16"
        >
          <path d="M0.5 72L48.5 0H72.5L22.5408 72H0.5Z" fill="#0B2152" />
        </svg>
      </h3>
      <ul className="recruit_border py-32 sm:py-64">
        <li
          className={`box-border text-3xl md:text-4xl align-middle inline-block w-1/2 lg:w-1/4 h-32 sm:h-28 ${
            tab == 0 && "selectList"
          }`}
        >
          <button
            className="w-full h-full flex flex-wrap content-center justify-center"
            onClick={() => settabHandler(0)}
          >
            Cable 설비
          </button>
        </li>
        <li
          className={`box-border text-3xl md:text-4xl align-middle inline-block w-1/2 lg:w-1/4 h-32 sm:h-28 ${
            tab == 1 && "selectList"
          }`}
        >
          <button
            className="w-full h-full flex flex-wrap content-center justify-center"
            onClick={() => settabHandler(1)}
          >
            Lumbar Support
          </button>
        </li>
        <li
          className={`box-border text-3xl md:text-4xl align-middle inline-block w-1/2 lg:w-1/4 h-32 sm:h-28 ${
            tab == 2 && "selectList"
          }`}
        >
          <button
            className="w-full h-full flex flex-wrap content-center justify-center"
            onClick={() => settabHandler(2)}
          >
            Wire 설비
          </button>
        </li>
        <li
          className={`box-border text-3xl md:text-4xl align-middle inline-block w-1/2 lg:w-1/4 h-32 sm:h-28 ${
            tab == 3 && "selectList"
          }`}
        >
          <button
            className="w-full h-full flex flex-wrap content-center justify-center"
            onClick={() => settabHandler(3)}
          >
            계측기 / 시험설비
          </button>
        </li>
      </ul>
      <div>
        {tab == 0 && <CableFacility />}
        {tab == 1 && <LumbarSupport />}
        {tab == 2 && <WireFacility />}
        {tab == 3 && <MeasureFacility />}
      </div>
    </div>
  );
};
