import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import { facilityData } from "./facilities";
import { Autoplay, Navigation, Pagination } from "swiper";
import SwiperCore from "swiper/core";
import back from "../../assets/icon/backArrow.png";
SwiperCore.use([Navigation, Pagination, Autoplay]);
export const CableFacility = () => {
  return (
    <div className="max-w-screen-xl overflow-hidden m-auto pb-40 sm:pb-80">
      <Swiper
        spaceBetween={0}
        loopAdditionalSlides={1}
        slidesPerView={1}
        speed={400}
        breakpoints={{
          768: {
            autoplay: false,
          },
        }}
        autoplay={{
          delay: 15000,
          disableOnInteraction: false,
        }}
        loop={true}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        pagination={{
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
        }}
        className={`facilS relative h-full`}
      >
        <button className="swiper-button-prev w-28 h-28 absolute left-0 top-1/2 transform -translate-y-1/2 hidden lg:flex flex-wrap content-center justify-center bg-main1 z-10">
          <img src={back} alt="뒤로가기" />
        </button>
        <button className="swiper-button-next w-28 h-28 absolute right-0 top-1/2 transform -translate-y-1/2 hidden lg:flex flex-wrap content-center justify-center bg-main1 z-10">
          <img src={back} alt="앞으로 가기" className="transform rotate-180" />
        </button>
        <div className="swiper-pagination absolute bottom-5 z-10 block w-full text-center"></div>
        {facilityData.video.length > 0 &&
          facilityData.video.map((obj) => (
            <SwiperSlide
              className={`flex flex-wrap flex-col content-center justify-center`}
            >
              <div className="max-w-screen-lg m-auto">
                <video
                  src={obj}
                  autoPlay
                  playsInline
                  loop
                  muted
                  className="w-full"
                ></video>
              </div>
            </SwiperSlide>
          ))}
        {facilityData.images.map((obj) => (
          <SwiperSlide
            className={`flex flex-wrap flex-col content-center justify-center`}
          >
            <div className="max-w-screen-lg m-auto">
              <img
                src={obj}
                alt="명보 cable 관련 설비 이미지"
                className="w-full"
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
