import "../css/intro.css";
import provide from "../../assets/images/intro/provide.png";
import innovation from "../../assets/images/intro/innovation.png";
import talent from "../../assets/images/intro/talent.png";
import continuity from "../../assets/images/intro/continuity.png";
export const CoreValue = () => {
  return (
    <div className="py-40 sm:py-80" id="CoreValue">
      <p className="font-bold text-5xl text-center esamanru text-main">
        핵심가치
      </p>
      <div className="headBar bg-main2 m-auto mt-11"></div>
      <div className="max-w-screen-lg mx-auto pt-32 sm:pt-64 px-6">
        <div className="flex flex-wrap flex-col sm:flex-row w-full">
          <div className="py-10 px-12">
            <img
              src={provide}
              alt="최선의 제품제공"
              className="sm:mx-0 mx-auto"
            />
          </div>
          <div className="px-8 sm:pl-16 py-8 sm:py-16 flex-1 space-y-6 sm:space-y-12">
            <p className="text-4xl sm:text-6xl esamanruM text-main py-4 px-7">
              최선의 제품제공
            </p>
            <p className="text-3xl sm:text-5xl px-7 py-4 leading-normal font-bold keep-all">
              정직과 신뢰를 바탕으로 믿고 사용할 수 있는 최선의 제품제공에
              만전을 기할 것입니다.
            </p>
          </div>
        </div>
        <div className="flex flex-wrap flex-col  w-full pt-12 sm:pt-24 sm:flex-row-reverse">
          <div className="py-10 px-12">
            <img src={innovation} alt="기술혁신" className="sm:mx-0 mx-auto" />
          </div>
          <div className="px-8 sm:pl-16 py-8 sm:py-16 flex-1 space-y-6 sm:space-y-12">
            <p className="text-4xl sm:text-6xl esamanruM text-main py-4 px-7">
              기술 혁신
            </p>
            <p className="text-3xl sm:text-5xl px-7 py-4 leading-normal font-bold keep-all">
              설비투자 및 R&D 기반의 기술 혁신을 추구합니다.
            </p>
          </div>
        </div>
        <div className="flex flex-wrap flex-col sm:flex-row w-full pt-12 sm:pt-24">
          <div className="py-10 px-12">
            <img src={talent} alt="인재양성" className="sm:mx-0 mx-auto" />
          </div>
          <div className="px-8 sm:pl-16 py-8 sm:py-16 flex-1 space-y-6 sm:space-y-12">
            <p className="text-4xl sm:text-6xl esamanruM text-main py-4 px-7">
              인재 양성
            </p>
            <p className="text-3xl sm:text-5xl px-7 py-4 leading-normal font-bold keep-all">
              인간중심 경영 기반의 역량 있는 인재양성을 추구합니다.
            </p>
          </div>
        </div>
        <div className="flex flex-wrap flex-col  w-full pt-12 sm:pt-24 sm:flex-row-reverse">
          <div className="py-10 px-12">
            <img src={continuity} alt="기술혁신" className="sm:mx-0 mx-auto" />
          </div>
          <div className="px-8 sm:pl-16 py-8 sm:py-16 flex-1 space-y-6 sm:space-y-12">
            <p className="text-4xl sm:text-6xl esamanruM text-main py-4 px-7">
              지속 가능
            </p>
            <p className="text-3xl sm:text-5xl px-7 py-4 leading-normal font-bold keep-all">
              창의적인 연구·개발 기반의 지속 가능한 미래를 추구합니다.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
