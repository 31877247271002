import React, { useState } from "react";
import { Fade } from "react-awesome-reveal";
import shape from "../../assets/icon/Shape.png";

export const History = () => {
  return (
    <div className="max-w-screen-lg w-full px-6 pb-8 sm:pb-16 pt-32 sm:pt-64 m-auto">
      <h3 className="text-4xl sm:text-6xl font-bold text-center">
        <svg
          viewBox="0 0 75 74"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="headerGuard inline-block mr-10 sm:mr-16"
        >
          <path d="M1.5 73L49.5 1H73.5L23.5408 73H1.5Z" stroke="#40AEB8" />
        </svg>

        <span className="leading-normal esamanru font-bold text-main">
          연혁
        </span>
        <svg
          viewBox="0 0 73 72"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="headerGuard inline-block ml-10 sm:ml-16"
        >
          <path d="M0.5 72L48.5 0H72.5L22.5408 72H0.5Z" fill="#0B2152" />
        </svg>
      </h3>
      <div className="mt-32 sm:mt-64">
        <ul className="tree m-auto my-0">
          <li className="h-64 relative">
            <div className="pillar absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2"></div>
            <div className="left-1/2 transform -translate-x-1/2 bg-gray-300 w-4 h-4 absolute -top-3 rounded-full"></div>
          </li>
          <li className="h-72 relative">
            <Fade triggerOnce={true}>
              <div className="pillar absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2"></div>
              <div className="his-point w-7 h-7 absolute -top-3 rounded-full"></div>
              <Fade delay={200} triggerOnce={true} direction="left">
                <div className="branch h-full relative">
                  <div className="his-textbox w-full h-full absolute">
                    <p className="text-main1 text-3xl sm:text-5xl leading-normal sm:leading-normal">
                      2019
                    </p>
                    <p className="text-main1 text-2xl sm:text-4xl leading-normal sm:leading-normal keep-all">
                      <span className="text-main2">02</span> SQ-MARK(주단조)
                      인증
                    </p>
                  </div>
                </div>
              </Fade>
              {/* <div className="now-point w-8 h-8 absolute -top-4 rounded-full">
              <div className="w-full absolute h-full rounded-full animate-ping bg-red-500"></div>
            </div> */}
            </Fade>
          </li>

          <li className="h-72 relative">
            <Fade>
              <div className="pillar absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2"></div>
              <div className="his-point w-7 h-7 absolute -top-3 rounded-full"></div>
              <Fade delay={200} triggerOnce={true} direction="right">
                <div className="branch h-full relative">
                  <div className="his-textbox w-full h-full absolute">
                    <p className="text-main1 text-3xl sm:text-5xl leading-normal sm:leading-normal">
                      2013
                    </p>
                    <p className="text-main1 text-2xl sm:text-4xl leading-normal sm:leading-normal keep-all">
                      <span className="text-main2">11 </span>SQ-MARK(전기조립)
                      인증
                    </p>
                  </div>
                </div>
              </Fade>
            </Fade>
          </li>
          <li className="h-72 relative">
            <Fade>
              <div className="pillar absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2"></div>
              <div className="his-point w-7 h-7 absolute -top-3 rounded-full"></div>
              <Fade delay={200} triggerOnce={true} direction="left">
                <div className="branch h-full relative">
                  <div className="his-textbox w-full h-full absolute">
                    <p className="text-main1 text-3xl sm:text-5xl leading-normal sm:leading-normal">
                      2010
                    </p>
                    <p className="text-main1 text-2xl sm:text-4xl leading-normal sm:leading-normal keep-all">
                      <span className="text-main2">03 </span> 확장이전
                    </p>
                  </div>
                </div>
              </Fade>
            </Fade>
          </li>
          <li className="h-72 relative">
            <Fade>
              <div className="pillar absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2"></div>
              <div className="his-point w-7 h-7 absolute -top-3 rounded-full"></div>
              <Fade delay={200} triggerOnce={true} direction="right">
                <div className="branch h-full relative">
                  <div className="his-textbox w-full h-full absolute">
                    <p className="text-main1 text-3xl sm:text-5xl leading-normal sm:leading-normal">
                      2009
                    </p>
                    <p className="text-main1 text-2xl sm:text-4xl leading-normal sm:leading-normal keep-all">
                      <span className="text-main2">08 </span> (주)명보 법인전환
                    </p>
                  </div>
                </div>
              </Fade>
            </Fade>
          </li>
          <li className="h-72 relative">
            <Fade>
              <div className="his-point w-7 h-7 absolute -top-3 rounded-full"></div>
              <Fade delay={200} triggerOnce={true} direction="left">
                <div className="branch h-full relative">
                  <div className="his-textbox w-full h-full absolute">
                    <p className="text-main1 text-3xl sm:text-5xl leading-normal sm:leading-normal">
                      2006
                    </p>
                    <p className="text-main1 text-2xl sm:text-4xl leading-normal sm:leading-normal keep-all">
                      <span className="text-main2">02 </span>{" "}
                      영하이텍(개인사업자)
                    </p>
                  </div>
                </div>
              </Fade>
            </Fade>
          </li>
        </ul>
      </div>
    </div>
  );
};
