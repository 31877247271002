import React from "react";
import video from "../../assets/video/machine.mp4";
import line1 from "../../assets/images/business/Lumbar-support-line.png";
import line2 from "../../assets/images/business/Factory_2_vector.png";

import { Fade } from "react-awesome-reveal";
import shape from "../../assets/icon/Frame.png";
import "../css/business.css";
export const LumbarLine = () => {
  return (
    <div className="">
      <Fade delay={300} triggerOnce={true}>
        <div id="robot" className="">
          <div className="stt text-center pb-32 sm:pb-64">
            <p className="font-bold text-5xl text-center esamanru text-main uppercase keep-all">
              Lumbar support 조립 Line
            </p>
            <div className="headBar bg-main2 m-auto mt-11"></div>
          </div>
          <div className="grid grid-cols-1 tracking-tight">
            {/* <img src={product1} alt="프레스 로봇 사진" /> */}
            <img src={line1} alt="이미지" className="w-full pb-32 sm:pb-64" />
            <table className=" info border-t-2 border-b-2 w-full border-gray-700">
              <tr className="border-b-2">
                <th className="font-bold table-th w-2/5 leading-loose sm:leading-loose tracking-tight">
                  차종
                </th>
                <td className="font table-td w-3/5 tracking-tight">
                  아반테 外
                </td>
              </tr>
              <tr className="border-b-2">
                <th className="font-bold table-th w-2/5 leading-loose sm:leading-loose tracking-tight">
                  생산 capa
                </th>
                <td className="font table-td w-3/5 tracking-tight">
                  2,000,000 EA / 년
                </td>
              </tr>
              <tr className="border-b-2">
                <th className="font-bold table-th w-2/5 leading-loose sm:leading-loose tracking-tight">
                  양산일
                </th>
                <td className="font table-td w-3/5 tracking-tight">
                  2018.02월
                </td>
              </tr>
              <tr className="border-b-2">
                <th className="font-bold table-th w-2/5 leading-loose sm:leading-loose tracking-tight">
                  설비명
                </th>
                <td className="font table-td w-3/5 tracking-tight uppercase">
                  허리지지대 조립 라인
                </td>
              </tr>
              <tr className="border-b-2">
                <th className="font-bold table-th w-2/5 leading-loose sm:leading-loose tracking-tight">
                  설비 구성
                </th>
                <td className="font table-td w-3/5 tracking-tight">
                  부품조립:18공정 / 방음실 소음검사
                </td>
              </tr>
              <tr className="">
                <th className="font-bold table-th w-2/5 leading-loose sm:leading-loose tracking-tight">
                  특징
                </th>
                <td className="font table-td w-3/5 tracking-tight">
                  <p>1. 자체 전용라인으로 셋업</p>
                  <p>2. 모든 stage Fool proof 적용</p>
                  <p>3. 이력 추적 데이터 저장</p>
                </td>
              </tr>
            </table>
          </div>
        </div>

        {/* <div id="amount" className="pt-24 sm:pt-48">
          <div className="stt text-center">
            <p className="font-bold text-5xl">Power Electric Head Rest</p>
            <div className="bg-gray-300 w-36 h-2 m-auto my-12"></div>
          </div>
          <div className="grid grid-cols-1 gap-y-8 md:grid-cols-2 md:gap-x-16 tranking-tight">
            <img alt="이미지" className="w-full h-96 bg-gray-200 " />
            <p className="text-main1 leading-normal sm:leading-normal text-2xl sm:text-3xl">
              Power Electric Head Reset 설명 <br></br> Lorem Ipsum is simply
              dummy text of the printing and typesetting industry. Lorem Ipsum
              has been the industry's standard dummy text ever since the 1500s,
              when an unknown printer took a galley of type and scrambled it to
              make a type specimen book. It has survived not only five
              centuries, but also the leap into electronic typesetting,
              remaining essentially unchanged.
            </p>
          </div>
        </div> */}
        {/* <div id="smart" className="pt-24 sm:pt-48">
          <div className="stt text-center">
            <p className="font-bold text-5xl">스마트 모빌리티</p>
            <div className="bg-gray-300 w-36 h-2 m-auto my-12"></div>
          </div>
          <div className="grid grid-cols-1 gap-y-8 md:grid-cols-2 md:gap-x-16 tranking-tight">
            <img src={product4} alt="프레스 로봇 사진" />
            <p className="text-main1 leading-normal sm:leading-normal text-2xl sm:text-3xl">
              전기차·수소차 등 친환경 모빌리티에 접목시킬 수 있는 친환경 소재
              개발을 위해 친환경 ISO 기업 인증을 비롯해 IATF 자동차 산업
              품질경영시스템, 안전보건경영시스템(ISO45001),
              품질경영시스템(ISO9001), 국제환경규격(ISO14000) 등 친환경 소재
              개발에 대한 각종 인증을 마쳤으며, 본격적인 부품 양산을 위한 준비에
              박차를 가하고 있습니다.
            </p>
          </div>
        </div> */}
      </Fade>
    </div>
  );
};
