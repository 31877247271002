import React from "react";
import { FOOTER } from "../../layout/footer";
import { SUBHEADER } from "../../layout/subheader";
import { Products } from "../../parts/business/products";
export const PRODUCTS = () => {
  return (
    <main id="content">
      <SUBHEADER />
      <div id="business">
        <Products />
      </div>
      <FOOTER />
    </main>
  );
};
