import first1 from "../../assets/images/business/facility/01/cable1.png";
import first2 from "../../assets/images/business/facility/01/cable2.png";
import second1 from "../../assets/images/business/facility/02/product16.jpg";
import third1 from "../../assets/images/business/facility/03/product40.png";
import third2 from "../../assets/images/business/facility/03/product41.png";
import third3 from "../../assets/images/business/facility/03/product44.png";

import fourth1 from "../../assets/images/business/facility/04/facility_04_01.png";
import fourth2 from "../../assets/images/business/facility/04/facility_04_02.png";
import fourth3 from "../../assets/images/business/facility/04/facility_04_03.jpg";
import cable from "../../assets/video/cablevideo.MP4";
import cable1 from "../../assets/video/cablevideo1.MP4";
import cable2 from "../../assets/video/cablevideo2.mp4";
import lumbar from "../../assets/video/lumbarV.MP4";
import lumbar1 from "../../assets/video/lumbarV1.MP4";
import lumbar2 from "../../assets/video/lumbarV2.MP4";
import lumbar3 from "../../assets/video/lumbarV3.MP4";

import wire from "../../assets/video/wireV.MP4";
import wire1 from "../../assets/video/wireV1.MP4";
import wire2 from "../../assets/video/wireV2.MP4";
export const facilityData = {
  video: [cable1, cable2],
  images: [first1, first2],
};
export const facilityData1 = {
  video: [lumbar, lumbar1, lumbar2, lumbar3],
  images: [],
};
export const facilityData2 = {
  video: [wire, wire1, wire2],
  images: [third1, third2, third3],
};
export const facilityData3 = {
  video: [],
  images: [fourth1, fourth3],
};
// {
//   video: [lumbar, lumbar1, lumbar2, lumbar3],
//   images: [second1],
// },
// {
//   video: [wire, wire1, wire2],
//   images: [third1, third2, third3],
// },
// {
//   video: [],
//   images: [fourth1, fourth2, fourth3],
// },
