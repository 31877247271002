import React from "react";
import { FOOTER } from "../../layout/footer";
import { SUBHEADER } from "../../layout/subheader";
import { FactoryStatus } from "../../parts/intro/FactoryStatus";
export const FACTORY = () => {
  return (
    <main id="content">
      <SUBHEADER />
      <div id="intro">
        <FactoryStatus />
      </div>
      <FOOTER />
    </main>
  );
};
