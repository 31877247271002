import React, { useState } from "react";
import { Fade } from "react-awesome-reveal";
import lab4 from "../../assets/images/labratory/lab4.png";
import cer1 from "../../assets/images/labratory/cer1.png";
import cer2 from "../../assets/images/labratory/cer2.png";
import cer3 from "../../assets/images/labratory/cer3.png";
import cer4 from "../../assets/images/labratory/cer4.png";
import cer5 from "../../assets/images/labratory/cer5.jpg";
import cer6 from "../../assets/images/labratory/cer6.png";
import cer7 from "../../assets/images/labratory/cer7.png";
import cer8 from "../../assets/images/labratory/cer8.png";
import "../css/recruit.css";
export const LabPerform = () => {
  const texts = [
    {
      para: "전기차용 열폭주 방지 버스바",
    },
    {
      para: "배터리 냉각호스",
    },
    {
      para: "에어 솔레노이드 밸브",
    },
    {
      para: "에어포켓 융착",
    },
  ];
  return (
    <div className="max-w-screen-xl m-auto pb-40 sm:pb-80 pt-32 sm:pt-64 px-6">
      <h3 className="text-5xl sm:text-6xl font-bold text-center">
        <svg
          viewBox="0 0 75 74"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="headerGuard inline-block mr-10 sm:mr-16"
        >
          <path d="M1.5 73L49.5 1H73.5L23.5408 73H1.5Z" stroke="#40AEB8" />
        </svg>

        <span className="leading-normal esamanru font-bold text-main">
          연구개발 실적
        </span>
        <svg
          viewBox="0 0 73 72"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="headerGuard inline-block ml-10 sm:ml-16"
        >
          <path d="M0.5 72L48.5 0H72.5L22.5408 72H0.5Z" fill="#0B2152" />
        </svg>
      </h3>
      <div className="wrap flex flex-wrap justify-between gap-y-24 pt-32 sm:pt-64">
        <Fade className="license" triggerOnce={true}>
          <div>
            <p className="text-center h-20 text-3xl sm:text-4xl text-main1">
              자동차 시트용 스프링의 제조장치
            </p>
            <div className="bg-gray-300 w-36 h-2 m-auto my-10"></div>
            <div className="w-full paper">
              <img
                src={cer1}
                alt="자동차 시트용 스프링의 제조장치 이미지"
                className="absolute w-full left-0 top-0 border h-full bg-gray-200"
              />
            </div>
          </div>
          <div>
            <p className="text-center h-20 text-3xl sm:text-4xl text-main1">
              자동차 시트용 와이어 고정클립
            </p>
            <div className="bg-gray-300 w-36 h-2 m-auto my-10"></div>
            <div className="w-full paper">
              <img
                src={cer2}
                alt="자동차 시트용 와이어 고정클립"
                className="absolute w-full left-0 top-0 border h-full bg-gray-200"
              />
            </div>
          </div>
          <div>
            <p className="text-center h-20 text-3xl sm:text-4xl text-main1">
              자동차 시트용 와이어 고정클립(관련디자인)
            </p>
            <div className="bg-gray-300 w-36 h-2 m-auto my-10"></div>
            <div className="w-full paper">
              <img
                src={cer3}
                alt="자동차 시트용 와이어 고정클립(관련디자인)"
                className="absolute w-full left-0 top-0 border h-full bg-gray-200"
              />
            </div>
          </div>
          <div>
            <p className="text-center h-20 text-3xl sm:text-4xl text-main1">
              튜브 어셈블리 제조 장치 및 튜브 어셈블리 제조 방법
            </p>
            <div className="bg-gray-300 w-36 h-2 m-auto my-10"></div>
            <div className="w-full paper">
              <img
                src={cer5}
                alt="튜브 어셈블리 제조 장치 및 튜브 어셈블리 제조 방법"
                className="absolute w-full left-0 top-0 border h-full bg-gray-200"
              />
            </div>
          </div>
          <div>
            <p className="text-center h-20 text-3xl sm:text-4xl text-main1">
              케이블 연결장치
            </p>
            <div className="bg-gray-300 w-36 h-2 m-auto my-10"></div>
            <div className="w-full paper">
              <img
                src={cer6}
                alt="케이블 연결장치"
                className="absolute w-full left-0 top-0 border h-full bg-gray-200"
              />
            </div>
          </div>
          <div>
            <p className="text-center h-20 text-3xl sm:text-4xl text-main1">
              컨트롤 케이블 인서트 다이캐스팅 주조성형 시스템
            </p>
            <div className="bg-gray-300 w-36 h-2 m-auto my-10"></div>
            <div className="w-full paper">
              <img
                src={cer8}
                alt="컨트롤 케이블 인서트 다이캐스팅 주조성형 시스템"
                className="absolute w-full left-0 top-0 border h-full bg-gray-200"
              />
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
};
