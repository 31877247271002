import { Modal } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ts from "typescript";
import { FOOTER } from "../../layout/footer";
import { SUBHEADER } from "../../layout/subheader";
import "../css/map.css";

interface EmailSubmitI {
  name: string;
  contact: string;
  email: string;
  desc: string;
}

export const INQUIRY = () => {
  const { getValues, register, handleSubmit } = useForm<EmailSubmitI>();
  const [open, setOpen] = useState(false);
  const [formState, setFormState] = useState({
    name: "",
    tel: "",
    mail: "",
    content: "",
  });
  const changeHandler = (e: any) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const submitHandler = () => {
    // setOpen(true);
    const { name, contact, email, desc } = getValues();
    //@ts-ignore
    window.Email.send({
      SecureToken: "62b935f6-f731-458f-8e89-4203747179f3",
      To: "sman017@naver.com",
      From: "sman017@naver.com",
      Subject: `${name}, ${contact}, ${email}`,
      Body: desc,
    }).then((rst: any) => {
      setOpen(true);
      // alert("문의 메일을 전송 했습니다.");

      // window.location.reload();
    });
  };
  const checkHandler = () => {
    window.location.href = "/";
  };
  const backdropHandler = () => {
    window.location.href = "/";
  };
  return (
    <main id="content">
      <SUBHEADER />
      <div
        id="intro"
        className="max-w-screen-lg w-full pt-32 md:pt-64 m-auto px-6"
      >
        <h3 className="text-4xl sm:text-6xl font-bold text-center">
          <svg
            viewBox="0 0 75 74"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="headerGuard inline-block mr-10 sm:mr-16"
          >
            <path d="M1.5 73L49.5 1H73.5L23.5408 73H1.5Z" stroke="#40AEB8" />
          </svg>

          <span className="leading-normal esamanru font-bold text-main">
            Contact Us
          </span>
          <svg
            viewBox="0 0 73 72"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="headerGuard inline-block ml-10 sm:ml-16"
          >
            <path d="M0.5 72L48.5 0H72.5L22.5408 72H0.5Z" fill="#0B2152" />
          </svg>
        </h3>
        <form onSubmit={handleSubmit(submitHandler)}>
          <div className="  pt-32 sm:pt-64 pb-40 sm:pb-80">
            <div className=" grid sm:grid-row-3 gap-x-10 gap-y-8  mb-8">
              <div>
                <p className="text-2xl sm:text-3xl pb-2">고객 성함</p>
                <input
                  {...register("name")}
                  placeholder="고객성함"
                  className="w-full border text-left px-8 py-2 text-2xl sm:text-3xl h-24"
                />
              </div>
              <div>
                <p className="text-2xl sm:text-3xl pb-2">고객 연락처</p>
                <input
                  {...register("contact")}
                  placeholder="연락처"
                  className="w-full border text-left px-8 py-2 text-2xl sm:text-3xl h-24"
                />
              </div>
              <div>
                <p className="text-2xl sm:text-3xl pb-2">고객 Email</p>
                <input
                  type="email"
                  {...register("email")}
                  placeholder="고객 Email"
                  className="w-full border text-left px-8 py-2 text-2xl sm:text-3xl h-24"
                />
              </div>
            </div>
            <div>
              <p className="text-2xl sm:text-3xl pb-2">전달내용</p>
              <textarea
                {...register("desc")}
                className="w-full h-56 border p-4 resize-none text-2xl sm:text-3xl mb-8"
                placeholder="문의사항을 남겨주시면 빠른 시일에 회신드리겠습니다. "
              ></textarea>
            </div>

            <div className="flex flex-wrap justify-end">
              <button
                className="w-56 h-16 text-center text-2xl sm:text-3xl bg-main1 rounded-xl"
                type="submit"
              >
                전송하기
              </button>
            </div>
          </div>
        </form>
      </div>

      <FOOTER />
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        onBackdropClick={backdropHandler}
      >
        <div className="contactUs bg-white absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <div className="flex flex-wrap">
            <h1 className="w-full top text-center ">메일발송확인 </h1>
            <p className="w-full middle text-center">
              문의 메일을 전송 했습니다.
            </p>
            <button
              className="w-full bottom border-gray-200"
              onClick={checkHandler}
            >
              확인
            </button>
          </div>
          {/* <div className=" py-2 text-center font-bold rounded-lg w-full text-2xl ">
            <p className="py-2">문의 메일이 전송되었습니다.</p>
            <div className="flex flex-wrap justify-end pr-4 pt-4">
              <button
                className=" w-36 h-10 text-center text-2xl sm:text-3xl bg-main1 rounded-xl"
                type="submit"
                // onClick={}
              >
                확인
              </button>
            </div>
          </div> */}
        </div>
      </Modal>
    </main>
  );
};
