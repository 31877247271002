import React, { useState } from "react";
import "../css/business.css";
import { Autoplay, Navigation, Pagination } from "swiper";
import SwiperCore from "swiper/core";
import "swiper/swiper.scss";
import { Cable } from "./cable";
import { Seat } from "./seat";
import { BendingWire } from "./bendingWire";

SwiperCore.use([Navigation, Pagination, Autoplay]);
export const Products = () => {
  document.body.classList.add("smooth");
  const [over, setover] = useState<boolean>(false);

  const [tab, settab] = useState<number>(0);
  const settabHandler = (num: number) => {
    settab(() => {
      return num;
    });
  };
  return (
    <div className="max-w-screen-lg w-full px-6 pt-32 sm:pt-64 m-auto">
      <h3 className="text-5xl sm:text-6xl font-bold text-center">
        <svg
          viewBox="0 0 75 74"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="headerGuard inline-block mr-10 sm:mr-16"
        >
          <path d="M1.5 73L49.5 1H73.5L23.5408 73H1.5Z" stroke="#40AEB8" />
        </svg>

        <span className="leading-normal esamanru font-bold text-main">
          제품소개
        </span>
        <svg
          viewBox="0 0 73 72"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="headerGuard inline-block ml-10 sm:ml-16"
        >
          <path d="M0.5 72L48.5 0H72.5L22.5408 72H0.5Z" fill="#0B2152" />
        </svg>
      </h3>
      <ul className="recruit_border py-32 sm:py-64">
        <li
          className={`box-border text-3xl md:text-4xl align-middle inline-block w-1/3 lg:w-1/3 h-32 sm:h-28 ${
            tab == 0 && "selectList"
          }`}
        >
          <button
            className="w-full h-full flex flex-wrap content-center justify-center"
            onClick={() => settabHandler(0)}
          >
            Seat
          </button>
        </li>
        <li
          className={`box-border text-3xl md:text-4xl align-middle inline-block w-1/3 lg:w-1/3 h-32 sm:h-28 ${
            tab == 1 && "selectList"
          }`}
        >
          <button
            className="w-full h-full flex flex-wrap content-center justify-center"
            onClick={() => settabHandler(1)}
          >
            Cable
          </button>
        </li>
        <li
          className={`box-border text-3xl md:text-4xl align-middle inline-block w-1/3 lg:w-1/3 h-32 sm:h-28 ${
            tab == 2 && "selectList"
          }`}
        >
          <button
            className="w-full h-full flex flex-wrap content-center justify-center"
            onClick={() => settabHandler(2)}
          >
            CNC Bending Wire
          </button>
        </li>
      </ul>
      <div className="">
        {tab == 0 && <Seat />}
        {tab == 1 && <Cable />}
        {tab == 2 && <BendingWire />}
      </div>
    </div>
  );
};
