import React, { useState } from "react";
import { FOOTER } from "../layout/footer";
import ReactPageScroller from "react-page-scroller";
import "./page.css";
import { HEADER } from "../layout/header";
import { Main } from "../parts/home/main";
import { Facility } from "../parts/home/facilities";
import { Product } from "../parts/home/products";
import { Strength } from "../parts/home/strength";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
export const HOME = () => {
  const [lastscroll, setscroll] = useState<boolean>(true);
  document.body.classList.add("noScrollBar");
  return isBrowser ? (
    <main id="content" className={`pc ${lastscroll ? "" : "banScroll"}`}>
      <HEADER />
      <Main />
      <FOOTER />
    </main>
  ) : (
    <main id="content" className="mobile">
      <HEADER />
      <Main />
      <FOOTER />
    </main>
  );
};
