import { useReactiveVar } from "@apollo/client";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { divide, url } from "../apollo";
import { FOOTER } from "../layout/footer";
import { SUBHEADER } from "../layout/subheader";
import { MainOffice } from "../parts/intro/mainOffice";
import { SecondFactory } from "../parts/intro/waytocome";

export const WORKPLACE = () => {
  const pathname = useReactiveVar(url);
  let numbering = 0;

  const [workplace, setWorkplace] = useState<number>(numbering);
  const workplaceHandler = (num: number) => {
    setWorkplace(() => {
      return num;
    });
  };
  return (
    <main id="content">
      <SUBHEADER />
      <div
        id="intro"
        className="max-w-screen-lg w-full pt-32 md:pt-64 m-auto px-6"
      >
        <h3 className="text-5xl sm:text-6xl font-bold text-center">
          <svg
            viewBox="0 0 75 74"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="headerGuard inline-block mr-10 sm:mr-16"
          >
            <path d="M1.5 73L49.5 1H73.5L23.5408 73H1.5Z" stroke="#40AEB8" />
          </svg>

          <span className="leading-normal esamanru font-bold text-main">
            오시는 길
          </span>
          <svg
            viewBox="0 0 73 72"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="headerGuard inline-block ml-10 sm:ml-16"
          >
            <path d="M0.5 72L48.5 0H72.5L22.5408 72H0.5Z" fill="#0B2152" />
          </svg>
        </h3>
        <ul className="recruit_border py-32 sm:py-64 ">
          <li
            className={`box-border text-3xl sm:text-4xl align-middle inline-block w-1/2 sm:w-1/2 h-28 ${
              workplace == 0 && "selectList"
            }`}
          >
            <button
              className="w-full h-full"
              onClick={() => workplaceHandler(0)}
            >
              명보 본사
            </button>
          </li>
          <li
            className={`box-border text-3xl sm:text-4xl align-middle inline-block w-1/2 sm:w-1/2 h-28 ${
              workplace == 1 && "selectList"
            }`}
          >
            <button
              className="w-full h-full"
              onClick={() => workplaceHandler(1)}
            >
              명보 1공장
            </button>
          </li>
        </ul>
        {workplace == 0 && <MainOffice />}
        {workplace == 1 && <SecondFactory />}
      </div>
      <FOOTER />
    </main>
  );
};
